<template>
    <div class="p-project">
        <div class="container-xl">
            <PageHeader :title="$route.params.name" :text="`${personasCount($route.params.name)} persona(s)`" goBack>
                <Button text="Share project" variant="outline-secondary" modalId="shareProjectModal" iconPosition="left" icon="icon-share" />
                <Button :to="`/${$route.params.name}/choose-persona-type`" text="Add persona" variant="secondary" iconPosition="left" icon="icon-plus" />
            </PageHeader>
            <div v-if="personasCount($route.params.name) > 0"  class="row">
                <div v-for="persona in personas($route.params.name)" :key="persona.id" class="col-md-6 col-12">
                    <Card :title="persona.name" :text="persona.description" :image="`${ persona.thumbnail ? persona.thumbnail : 'https://via.placeholder.com/160x160'}`" :toEdit="`/${$route.params.name}/edit/${persona.personaType}/${persona.id}`" :toPreview="`/${$route.params.name}/preview/${persona.personaType}/${persona.id}`" :draft="!persona.isPublished" />
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <div class="p-project__no-persona d-flex flex-column align-items-center justify-content-center">
                        <h4>No persona created yet!</h4>
                        <Button :to="`/${$route.params.name}/choose-persona-type`" variant="primary" text="Add persona" icon="icon-plus" iconPosition="left" />
                    </div>
                </div>
            </div>
        </div>

        <Modal id="shareProjectModal" title="Share your project" :subtitle="`${$route.params.name} (${personasCount($route.params.name)} personas)`">
            <form>
                <div class="form-group mt-40 mb-40">
                    <label for="passwordProtectInput" class="form-label">Password protect</label>
                    <div class="d-flex align-items-center">
                        <input type="text" class="form-control form-control--sm mr-16 savePasswordInput" id="passwordProtectInput" aria-describedby="passwordProtect" v-model="pvtData">
                        <button type="button" id="savePasswordBtn" class="btn btn-primary" @click="encryptData(this.id, 'savePasswordBtn')">Save</button>
                    </div>
                </div>
                <div class="form-group">
                    <label for="copyUrlInput" class="form-label">Copy this private URL to share</label>
                    <div class="d-flex align-items-center">
                        <input type="text" class="form-control mr-16 copyUrlInput" id="copyUrlInput" aria-describedby="copyUrl" :value="`${this.protocol}//${this.hostname}${getPort()}/share/${$route.params.name}/auth`" disabled>
                        <button type="button" id="copyBtn" class="btn btn-secondary copy-link" :data-clipboard-text="`${this.protocol}//${this.hostname}${getPort()}/share/${$route.params.name}/auth`" @click="copyUrl($event, $route.params.name)">Copy</button>
                        <span id="shareModal__success-msg" class="success-msg">Copied to clipboard</span>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Card from '../components/Card.vue';
    import Button from '../components/Button.vue';
    import Modal from '../components/Modal.vue';
    import PageHeader from '../components/PageHeader.vue';

    export default {
        name: 'Project',

        components: {
            Card,
            Button,
            Modal,
            PageHeader,
        },

        computed: {
            ...mapGetters({
                personasCount: 'personas/countPersonaByProject',
                personaByProject: 'personas/personaByProject',
                projectsFiles: 'projects/projects',
            }),
            personasTotal() {
                return this.personasCount
            },
            personas() {
                return this.personaByProject
            },
            projects() {
                return this.projectsFiles
            },

            hostname() {
                return  window.location.hostname
            },
            protocol() {
                return window.location.protocol
            },

            id() {
                let value = '';
                for(let i = 0; i < this.projects.length; i++) {
                    if(this.projects[i].name == this.$route.params.name) {
                        value = this.projects[i].id;
                    }
                }
                return value;
            }
        },

        data() {
            return {
                pvtData: "",
                secret: "123#$%",
                encData: "",  
            }
        },

        methods: {
            ...mapActions({
                fetchPersona: 'personas/fetchPersonas',
                savePassword: 'projects/savePassword',
            }),

            copyUrl(event) {
                const copyText = event.target.getAttribute('data-clipboard-text');
                const successMsg = document.getElementById('shareModal__success-msg');
                const copyBtn = document.getElementById('copyBtn');
                const thisModal = document.getElementById('shareProjectModal');

                this.$copyText(copyText, thisModal).then(function (e) {
                    console.log(e)
                    successMsg.classList.add('show');
                    copyBtn.innerHTML = '<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m19.2928932 5.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0 .3604839.36048396.3882135.92771502.0831886 1.32000622l-.0831886.09420734-11.00000002 11.00000002c-.36048396.3604839-.92771502.3882135-1.32000622.0831886l-.09420734-.0831886-5-5c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136.36048396-.3604839.92771502-.3882135 1.32000622-.0831886l.09420734.0831886 4.29289322 4.2921068z" fill="#242831"/></svg>';
                    setTimeout(function(){
                        successMsg.classList.remove('show');
                        copyBtn.innerHTML = 'Copy';
                    }, 3000);
                }, function (e) {
                    alert('Can not copy')
                    console.log(e)
                })
            },

            encryptData(projectId, btnId) {
                var CryptoJS = require("crypto-js");
                if (this.pvtData.length) {
                    // hash the name with any algorithm
                    const data = CryptoJS.AES.encrypt(this.pvtData, this.secret).toString();

                    // Save password in firebase doc
                    let payload = { 'projectId': projectId, 'newPassword': data, 'id': btnId, };
                    this.savePassword(payload);
                }
            },

            getPort() {
                if(window.location.port) {
                    return ':' + window.location.port
                } else {
                    return '';
                }
            },
        },

        created() {
            this.$store.dispatch('personas/fetchPersonas');
        },
    }
</script>
